import { useState } from "react"

import { MChip } from "src/components/MChip/MChip"
import XIcon from "src/ui/icons/x.svg"

export function MChipDemo() {
  const [clickText, setClickText] = useState("")

  return (
    <div>
      <p>{clickText}</p>
      <br />
      <MChip
        label="Chip 1"
        Icon={XIcon}
        onClick={() => {
          setClickText("Chip 1 clicked")
        }}
      />
      <MChip
        label="Chip 2"
        Icon={XIcon}
        onClick={() => {
          setClickText("Chip 2 clicked")
        }}
      />
    </div>
  )
}
