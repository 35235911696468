import styled from "styled-components"

import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import { ImgCard } from "src/components/Reports/ImgCard"
import { breakpoint } from "src/constants/breakpoints"
import { MButton } from "src/ui/Button/MButton"
import imgSofaSliver from "src/ui/images/photo-sofa-sliver.jpg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ImageCardDemo() {
  return (
    <ImgCard
      src={imgSofaSliver}
      alt="sofa"
      sources={[{ media: breakpoint.smallUp, srcSet: imgSofaSliver }]}
    >
      <FlexBox>
        <MText variant="heading3">Noise incident report</MText>
        <UpgradeIconButton context={"incident_reports"} />
      </FlexBox>

      <MText color="secondary">
        Document noise levels and communication for a claim or noise complaint.
      </MText>

      <ButtonBox>
        <MButton size="medium" variant="secondary" onClick={() => {}}>
          Create report
        </MButton>
      </ButtonBox>
    </ImgCard>
  )
}

const ButtonBox = styled.div`
  margin-top: ${spacing.XL};
`

const FlexBox = styled.div`
  display: flex;
  gap: ${spacing.S};
  align-items: center;
`
