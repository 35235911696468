import { useState } from "react"
import styled from "styled-components"

import { IconButton } from "@minuthq/meatball-ui-react"

import { MButton, TMButtonProps } from "src/ui/Button/MButton"
import { colorScale } from "src/ui/colors"
import { Divider } from "src/ui/Divider/Divider"
import PlusIcon from "src/ui/icons/plus.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MButtonDemo() {
  const [size, setSize] = useState<"medium" | "small">("medium")
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [startAdornment, setStartAdornment] = useState(false)
  const [endAdornment, setEndAdornment] = useState(false)

  return (
    <Box>
      <Configuration>
        <select
          onChange={(e) => setSize(e.target.value as "medium" | "small")}
          value={size}
        >
          <option value={"medium"}>Medium (default)</option>
          <option value={"small"}>Small</option>
        </select>

        <label>
          <MText variant="bodyS" color="secondary" as="span">
            Disabled
          </MText>
          <input
            type="checkbox"
            checked={disabled}
            onChange={(e) => setDisabled(e.target.checked)}
          ></input>
        </label>
        <label>
          <MText variant="bodyS" color="secondary" as="span">
            Loading
          </MText>
          <input
            type="checkbox"
            checked={loading}
            onChange={(e) => setLoading(e.target.checked)}
          ></input>
        </label>
        <label>
          <MText variant="bodyS" color="secondary" as="span">
            Start adornment
          </MText>
          <input
            type="checkbox"
            checked={startAdornment}
            onChange={(e) => setStartAdornment(e.target.checked)}
          ></input>
        </label>
        <label>
          <MText variant="bodyS" color="secondary" as="span">
            End adornment
          </MText>
          <input
            type="checkbox"
            checked={endAdornment}
            onChange={(e) => setEndAdornment(e.target.checked)}
          ></input>
        </label>
      </Configuration>

      <StyledDivider />

      <div>
        <Title>Default button</Title>
        <ButtonsWrapper>
          <ButtonBox>
            <MButton
              disabled={disabled}
              size={size}
              loading={loading}
              startIcon={
                startAdornment && <PlusIcon width={getIconSize(size)} />
              }
              endIcon={endAdornment && <PlusIcon width={getIconSize(size)} />}
            >
              Primary
            </MButton>
            <MButton
              disabled={disabled}
              size={size}
              loading={loading}
              variant="secondary"
              startIcon={
                startAdornment && <PlusIcon width={getIconSize(size)} />
              }
              endIcon={endAdornment && <PlusIcon width={getIconSize(size)} />}
            >
              Secondary
            </MButton>
            <MButton
              disabled={disabled}
              size={size}
              loading={loading}
              variant="subtle"
              startIcon={
                startAdornment && <PlusIcon width={getIconSize(size)} />
              }
              endIcon={endAdornment && <PlusIcon width={getIconSize(size)} />}
            >
              Subtle
            </MButton>
            <MButton
              disabled={disabled}
              size={size}
              loading={loading}
              variant="minimal"
              startIcon={
                startAdornment && <PlusIcon width={getIconSize(size)} />
              }
              endIcon={endAdornment && <PlusIcon width={getIconSize(size)} />}
            >
              Minimal
            </MButton>
          </ButtonBox>
          <ButtonBox>
            <IconButton
              size={size}
              disabled={disabled}
              loading={loading}
              Icon={PlusIcon}
            />
            <IconButton
              variant="secondary"
              size={size}
              disabled={disabled}
              loading={loading}
              Icon={PlusIcon}
            />
            <IconButton
              variant="subtle"
              size={size}
              disabled={disabled}
              loading={loading}
              Icon={PlusIcon}
            />
            <IconButton
              variant="minimal"
              size={size}
              disabled={disabled}
              loading={loading}
              Icon={PlusIcon}
            />
          </ButtonBox>
        </ButtonsWrapper>
      </div>

      <StyledDivider />

      <div>
        <Title>Destructive button</Title>
        <ButtonsWrapper>
          <ButtonBox>
            <MButton
              variant="primary"
              color="destructive"
              disabled={disabled}
              size={size}
              loading={loading}
              startIcon={
                startAdornment && <PlusIcon width={getIconSize(size)} />
              }
              endIcon={endAdornment && <PlusIcon width={getIconSize(size)} />}
            >
              Primary
            </MButton>
            <MButton
              variant="secondary"
              color="destructive"
              disabled={disabled}
              loading={loading}
              size={size}
              startIcon={
                startAdornment && <PlusIcon width={getIconSize(size)} />
              }
              endIcon={endAdornment && <PlusIcon width={getIconSize(size)} />}
            >
              Secondary
            </MButton>
            <MButton
              variant="subtle"
              color="destructive"
              disabled={disabled}
              size={size}
              loading={loading}
              startIcon={
                startAdornment && <PlusIcon width={getIconSize(size)} />
              }
              endIcon={endAdornment && <PlusIcon width={getIconSize(size)} />}
            >
              Subtle
            </MButton>
            <MButton
              variant="minimal"
              color="destructive"
              disabled={disabled}
              size={size}
              loading={loading}
              startIcon={
                startAdornment && <PlusIcon width={getIconSize(size)} />
              }
              endIcon={endAdornment && <PlusIcon width={getIconSize(size)} />}
            >
              Minimal
            </MButton>
          </ButtonBox>
          <ButtonBox>
            <IconButton
              color="destructive"
              size={size}
              disabled={disabled}
              loading={loading}
              Icon={PlusIcon}
            />
            <IconButton
              variant="secondary"
              color="destructive"
              size={size}
              disabled={disabled}
              loading={loading}
              Icon={PlusIcon}
            />
            <IconButton
              variant="subtle"
              color="destructive"
              size={size}
              disabled={disabled}
              loading={loading}
              Icon={PlusIcon}
            />
            <IconButton
              variant="minimal"
              color="destructive"
              size={size}
              disabled={disabled}
              loading={loading}
              Icon={PlusIcon}
            />
          </ButtonBox>
        </ButtonsWrapper>
      </div>

      <StyledDivider />

      <div>
        <Title>Contrast button</Title>
        <ButtonsWrapper>
          <ContrastBox>
            <MButton
              variant="primary"
              color="contrast"
              disabled={disabled}
              size={size}
              loading={loading}
              startIcon={
                startAdornment && <PlusIcon width={getIconSize(size)} />
              }
              endIcon={endAdornment && <PlusIcon width={getIconSize(size)} />}
            >
              Primary
            </MButton>
            <MButton
              variant="secondary"
              color="contrast"
              disabled={disabled}
              size={size}
              loading={loading}
              startIcon={
                startAdornment && <PlusIcon width={getIconSize(size)} />
              }
              endIcon={endAdornment && <PlusIcon width={getIconSize(size)} />}
            >
              Secondary
            </MButton>
            <MButton
              variant="subtle"
              color="contrast"
              disabled={disabled}
              size={size}
              loading={loading}
              startIcon={
                startAdornment && <PlusIcon width={getIconSize(size)} />
              }
              endIcon={endAdornment && <PlusIcon width={getIconSize(size)} />}
            >
              Subtle
            </MButton>
            <MButton
              variant="minimal"
              color="contrast"
              disabled={disabled}
              size={size}
              loading={loading}
              startIcon={
                startAdornment && <PlusIcon width={getIconSize(size)} />
              }
              endIcon={endAdornment && <PlusIcon width={getIconSize(size)} />}
            >
              Minimal
            </MButton>
          </ContrastBox>
          <ContrastBox>
            <IconButton
              color="contrast"
              size={size}
              disabled={disabled}
              loading={loading}
              Icon={PlusIcon}
            />
            <IconButton
              variant="secondary"
              color="contrast"
              size={size}
              disabled={disabled}
              loading={loading}
              Icon={PlusIcon}
            />
            <IconButton
              variant="subtle"
              color="contrast"
              size={size}
              disabled={disabled}
              loading={loading}
              Icon={PlusIcon}
            />
            <IconButton
              variant="minimal"
              color="contrast"
              size={size}
              disabled={disabled}
              loading={loading}
              Icon={PlusIcon}
            />
          </ContrastBox>
        </ButtonsWrapper>
      </div>
    </Box>
  )
}

function Title({ children }: { children: React.ReactNode }) {
  return (
    <MText variant="subtitle" marginBottom={spacing.M}>
      {children}
    </MText>
  )
}

function getIconSize(size: TMButtonProps["size"]) {
  switch (size) {
    case "medium":
      return 20
    case "small":
      return 16
    default:
      return 20
  }
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

const Configuration = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.M};
`

const ButtonsWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const ButtonBox = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
`

const ContrastBox = styled(ButtonBox)`
  background: ${colorScale.hejmo[700]};
  padding: ${spacing.L};
  border-radius: 6px;
`

const StyledDivider = styled(Divider)`
  margin-block: ${spacing.L};
`
