import { UpgradeComponentBlocker } from "src/components/FeatureBlockers/UpgradeComponentBlocker"
import { MButton } from "src/ui/Button/MButton"

export function UpgradeBlockerComponentDemo() {
  return (
    <div>
      <UpgradeComponentBlocker
        eventProperties={{ context: "demo" }}
        showUpgradeBlocker={true}
        requiredPlan={"pro"}
      >
        <MButton disabled={true}>Access Pro feature</MButton>
      </UpgradeComponentBlocker>
    </div>
  )
}
