import { useMemo, useState } from "react"

import { Divider } from "src/ui/Divider/Divider"
import {
  DropdownSelect,
  TSelectOption,
  TSelectOptions,
} from "src/ui/DropdownSelect/DropdownSelect"
import { isGroupSelectOption } from "src/ui/DropdownSelect/utils"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const colorOptions = [
  {
    label: "Red",
    value: "red",
  },
  {
    label: "Blue",
    value: "blue",
  },
  {
    label: "Green",
    value: "green",
  },
  {
    label: "Purple",
    value: "purple",
  },
  {
    label: "Orange",
    value: "orange",
  },
  {
    label: "Black",
    value: "black",
  },
  {
    label: "White",
    value: "white",
  },
  {
    label: "Pink",
    value: "pink",
  },
  {
    label: "Brown",
    value: "brown",
  },
]

const groupedColourOptions = [
  {
    title: "CYM Colors",
    options: [
      {
        label: "Cyan",
        value: "cyan",
      },
      {
        label: "Yellow",
        value: "yellow",
      },
      {
        label: "Magenta",
        value: "magenta",
      },
    ],
  },
  ...colorOptions,
]

export function DropdownSelectDemo() {
  const [searchText, setSearchText] = useState("")

  const [selectedOption, setSelectedOption] = useState<TSelectOption<string>>()

  const filteredOptions = useMemo(() => {
    return colorOptions.filter((option) =>
      option.label.toLowerCase().includes(searchText.toLowerCase())
    )
  }, [searchText])

  const filteredGroupOptions: TSelectOptions = useMemo(() => {
    const mapped = groupedColourOptions.map((opt) => {
      if (isGroupSelectOption(opt)) {
        return {
          ...opt,
          options: opt.options.filter((opt) =>
            opt.label.toLowerCase().includes(searchText.toLowerCase())
          ),
        }
      }
      return opt
    })

    return mapped.filter((option) => {
      if (isGroupSelectOption(option)) {
        return option.options.length > 0
      }
      return (option as TSelectOption).label
        .toLowerCase()
        .includes(searchText.toLowerCase())
    })
  }, [searchText])

  return (
    <div>
      <MText variant="heading3" marginBottom={spacing.M}>
        Default
      </MText>
      <DropdownSelect
        label={selectedOption?.label || "Select color"}
        options={filteredOptions}
        selectedValue={selectedOption?.value || ""}
        onChange={({ option }) => setSelectedOption(option)}
        searchText={searchText}
        onSearch={setSearchText}
        placeholder="Search"
        onClearSelection={() => {
          setSelectedOption(undefined)
          setSearchText("")
        }}
      />

      <Divider $margin={spacing.XL} />

      <MText variant="heading3" marginBottom={spacing.M}>
        With checkmark
      </MText>
      <DropdownSelect
        label={selectedOption?.label || "Select color"}
        options={filteredOptions}
        selectedValue={selectedOption?.value || ""}
        onChange={({ option }) => setSelectedOption(option)}
        searchText={searchText}
        onSearch={setSearchText}
        placeholder="Search"
        onClearSelection={() => {
          setSelectedOption(undefined)
          setSearchText("")
        }}
        showCheckmark
      />

      <Divider $margin={spacing.XL} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Grouped options
      </MText>

      <DropdownSelect
        label={selectedOption?.label ?? "Select color"}
        options={filteredGroupOptions}
        selectedValue={selectedOption?.value || ""}
        onChange={({ option }) => setSelectedOption(option)}
        searchText={searchText}
        onSearch={setSearchText}
        placeholder="Search"
        onClearSelection={() => {
          setSelectedOption(undefined)
          setSearchText("")
        }}
      />

      <Divider $margin={spacing.XL} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Minimal
      </MText>

      <DropdownSelect
        label={selectedOption?.label || "Select color"}
        options={filteredOptions}
        selectedValue={selectedOption?.value || ""}
        onChange={({ option }) => setSelectedOption(option)}
        searchText={searchText}
        onSearch={setSearchText}
        mButtonVariant="minimal"
        mTextVariant="body"
        placeholder="Search"
        size="medium"
      />
    </div>
  )
}
