import { IconButton } from "@minuthq/meatball-ui-react"

import { ParadiseButtonGroup } from "src/components/Paradise/ParadiseButtonGroup"
import { MButton } from "src/ui/Button/MButton"
import ChevronIcon from "src/ui/icons/chevron-down.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ParadiseButtonGroupDemo() {
  return (
    <div>
      <MText variant="heading3" marginBottom={spacing.S}>
        All primary buttons
      </MText>
      <ParadiseButtonGroup>
        <MButton variant="primary">Button 1</MButton>
        <MButton variant="primary">Button 2</MButton>
        <MButton variant="primary">Button 3</MButton>
        <IconButton Icon={ChevronIcon} variant="primary" />
      </ParadiseButtonGroup>
      <br />
      <br />
      <ParadiseButtonGroup>
        <MButton variant="secondary">Button 1</MButton>
        <MButton variant="secondary">Button 2</MButton>
        <MButton variant="secondary">Button 3</MButton>
        <IconButton Icon={ChevronIcon} variant="secondary" />
      </ParadiseButtonGroup>
      <br />
      <br />
      <ParadiseButtonGroup>
        <MButton variant="subtle">Button 1</MButton>
        <MButton variant="subtle">Button 2</MButton>
        <MButton variant="subtle">Button 3</MButton>
        <IconButton Icon={ChevronIcon} variant="subtle" />
      </ParadiseButtonGroup>
    </div>
  )
}
