import styled from "styled-components"

import { DesktopNotificationsToggle } from "src/components/Notifications/DesktopNotificationsToggle"
import { useNotifications } from "src/components/Notifications/useNotifications"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { DebugBox } from "src/ui/Debug/DebugBox"
import { debug } from "src/utils/logger"
import { getBrowserSystemInfo } from "src/utils/systemUtil"

export function NotificationsDemo() {
  const { navigate } = useRouter()
  const {
    notificationsEnabled,
    browserPermission,
    showNotification,
    firebaseToken,
    backendNotificationsEnabled,
    setBackendNotificationsEnabled,
  } = useNotifications()

  function handleTestNotification() {
    const title = "Test Notification Title"
    const body = `This is a test notification with an onClick handler and a custom logo.`
    const icon = `/logo512.png`
    showNotification({
      title,
      body,
      icon,
      onClick: (event: Event) => {
        debug.info("Test notification clicked")
        event.preventDefault() // prevents the browser from focusing the Notification's tab
        return navigate(Routes.Homes.location())
      },
    })
  }

  return (
    <Grid>
      <DesktopNotificationsToggle />

      <DebugBox
        title="Notifications"
        data={{
          notificationsEnabled,
          browserPermission,
          backendNotificationsEnabled,
          token: String(firebaseToken),
        }}
      >
        <div>{JSON.stringify(getBrowserSystemInfo())}</div>
        <MButton onClick={handleTestNotification}>
          Send test notification
        </MButton>

        <MButton
          onClick={() =>
            setBackendNotificationsEnabled(!backendNotificationsEnabled)
          }
          variant="minimal"
        >
          Toggle BE notifications {backendNotificationsEnabled ? "off" : "on"}
        </MButton>
      </DebugBox>
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  gap: 1rem;
`
