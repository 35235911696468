import { useState } from "react"

import { MRadioButton } from "src/ui/MRadioButton/MRadioButton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MRadioButtonDemo() {
  const [error, setError] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [label, setLabel] = useState("Label")
  const [selected, setSelected] = useState("first")

  return (
    <div>
      <label>
        <input
          type="checkbox"
          checked={error}
          onChange={(e) => setError(e.target.checked)}
        />
        Has error
      </label>
      <br />
      <label>
        <input
          type="checkbox"
          checked={disabled}
          onChange={(e) => setDisabled(e.target.checked)}
        />
        Is disabled
      </label>
      <br />
      <input
        placeholder="Label"
        value={label}
        onChange={(e) => setLabel(e.target.value)}
      />

      <MText variant="subtitle" marginBottom={spacing.XS}>
        Example
      </MText>
      <MText variant="subtitle" marginBottom={spacing.M}>
        Selected value: {selected}
      </MText>
      <div
        style={{
          display: "grid",
          gap: spacing.S,
        }}
      >
        <MRadioButton
          name="demo-radio"
          selected={selected === "first"}
          value="first"
          onSelected={(v) => {
            setSelected(v)
          }}
          label={label}
          error={error}
          disabled={disabled}
        />
        <MRadioButton
          name="demo-radio"
          selected={selected === "second"}
          value="second"
          onSelected={(v) => {
            setSelected(v)
          }}
          label={label}
          error={error}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
