import { ChangeEvent } from "react"
import styled from "styled-components"

import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type HTMLRadioButtonProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  | "type"
  | "name"
  | "onChange"
  | "checked"
  | "disabled"
  | "value"
  | "children"
  | "className"
  | "style"
  | "required"
  | "defaultChecked"
>

type MRadioButtonProps = {
  label: string
  /**
   * The name of the radio button group
   */
  name: string
  /**
   * The value of the radio button.
   * This value will be passed to the `onSelected` callback when the radio button is selected.
   */
  value: string
  /**
   * Whether the radio button is selected.
   */
  selected?: boolean
  /**
   * Callback that is called when the radio button is selected.
   *
   * @param newValue The value of the radio button.
   * @param event The change event.
   */
  onSelected?: (newValue: string, event: ChangeEvent) => void
  disabled?: boolean
  error?: boolean
  /**
   * Whether the radio button group is required.
   */
  required?: boolean
  inputProps?: HTMLRadioButtonProps
}

export function MRadioButton({
  label,
  name,
  value,
  selected,
  onSelected,
  disabled,
  error,
  required,
  inputProps,
}: MRadioButtonProps) {
  return (
    <StyledLabel>
      <StyledRadioButton $error={error}>
        <div className="inner" />
      </StyledRadioButton>
      <VisuallyHiddenInput
        type="radio"
        name={name}
        defaultChecked={selected}
        onChange={(e) => {
          onSelected?.(e.target.value, e)
        }}
        disabled={disabled}
        required={required}
        value={value}
        {...inputProps}
      />
      <MText color="unset">{label}</MText>
    </StyledLabel>
  )
}

const StyledLabel = styled.label`
  display: inline-flex;
  align-items: center;
  gap: ${spacing.S};
  cursor: pointer;

  &:has(input:disabled) {
    cursor: auto;
    color: ${mColors.neutralDark2};
  }
`

const StyledRadioButton = styled.div<{ $error?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 2px solid
    ${({ $error }) => ($error ? mColors.systemError : mColors.neutralDark3)};
  border-radius: 9999px;
  transition: border-color 100ms;

  .inner {
    width: 10px;
    height: 10px;
    border-radius: 9999px;
    background-color: ${mColors.primary};
    opacity: 0;
    transition: opacity 100ms;
  }

  &:hover:not(:has(+ input:disabled)) {
    border-color: ${mColors.primary};
  }

  &:has(+ input:checked:not(:disabled)) {
    border-color: ${mColors.primary};

    .inner {
      opacity: 1;
    }
  }

  &:has(+ input:disabled) {
    border-color: ${mColors.neutralDark2};
  }

  &:has(+ input:focus-visible) {
    outline: 1px solid ${mColors.textPrimary};
    outline-offset: 1px;
  }
`

const VisuallyHiddenInput = styled.input`
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
`
