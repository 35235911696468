import { useState } from "react"

import { Dropzone } from "src/ui/Dropzone/Dropzone"

export function DropzoneDemo() {
  const [file, setFile] = useState<File | null>(null)
  const [description, setDescription] = useState("")
  const [loading, setLoading] = useState(false)

  return (
    <div>
      <label>
        Description
        <input onChange={(e) => setDescription(e.target.value)} />
      </label>
      <br />
      <label>
        <input type="checkbox" onChange={(e) => setLoading(e.target.checked)} />
        Loading
      </label>

      <Dropzone
        file={file}
        onChange={(file) => setFile(file)}
        loading={loading}
        description={description}
        error={
          !!file && file?.type !== "text/csv" ? "File must be a CSV" : undefined
        }
        inputProps={{ accept: "text/csv" }}
      />
    </div>
  )
}
