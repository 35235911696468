import { MButton } from "src/ui/Button/MButton"
import { mColors } from "src/ui/colors"
import CheckIcon from "src/ui/icons/check-circle.svg"
import ErrorIcon from "src/ui/icons/error.svg"
import { Timeline } from "src/ui/Timeline/Timeline"

export function TimelineDemo() {
  return (
    <Timeline
      timeline={[
        {
          indicator: {
            Icon: CheckIcon,
          },
          timestamp: "2021-09-23T00:00:00.000Z",
          description:
            "This is a description very long description lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          content: <MButton size="small">Some button</MButton>,
        },
        {
          timestamp: "2021-09-23T00:00:00.000Z",
          description: "This is a description",
        },
        {
          indicator: {
            Icon: ErrorIcon,
            color: mColors.systemError,
          },
          timestamp: "2021-09-23T00:00:00.000Z",
          description: "This is a description",
        },
        {
          indicator: {
            Icon: ErrorIcon,
            color: mColors.systemWarning,
          },
          timestamp: "2021-09-22T00:00:00.000Z",
          description: "This is a description",
          content: (
            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <MButton size="small" variant="secondary">
                Some button
              </MButton>
              <MButton size="small" variant="minimal">
                Other button
              </MButton>
            </div>
          ),
        },
        {
          timestamp: "2021-09-22T00:00:00.000Z",
          description: "This is a description",
        },
      ]}
    />
  )
}
